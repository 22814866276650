import React from 'react';

function Experience() {
  return (
    <section className="tc-experience-style1 section-padding-x">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-2">
            <div className="exp-num mb-100 wow zoomIn" data-wow-delay="0.3s">
              <h2 className="num"> 15 </h2>
              <h5 className="fsz-18 text-uppercase">
                yıllık <br /> tecrübe
              </h5>
              
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img wow">
              <img
                src="/home1/assets/img/exp.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="info wow fadeInUp" data-wow-delay="0.3s">
              <h3 className="fsz-45 fw-600 mb-30"> 1951'den beri </h3>
              <div className="text fsz-15 color-666">
                ATA Mimarlık’ta yapı sektörünün dinamikleri; inşaat, mimari, elektrik ve mekanik bir çatı altında toplanmıştır. Firma kurucularının mesleki faaliyetleri 95′li yılların başında çeşitli işletmelerde çalışarak başlamıştır. 15 yıllık bilgi birikimi ile şirketler grubu haline gelmiştir. Geçmişi yıllara dayanan geniş bilgi ve tecrübesiyle; yetişmiş, genç, hızlı ekibi ile çeşitli sektörlere günümüz teknolojisine uygun hizmet vermektedir.
              </div>
              <a
                href="#"
                className="butn rounded-pill mt-50 hover-bg-black bg-white"
              >
                <span>
                  Bize Ulaş <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="rotate-txt justify-content-lg-end">
              <ul>
                <li>
                  <a href="#"> info@atamimari.com.tr </a>
                </li>
                <li>
                  <a href="#"> +90 (216) 327 55 58 </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img src="/home1/assets/img/c_line.png" alt="" className="c-line wow" />
    </section>
  );
}

export default Experience;
