import React from 'react';

function Header() {
  return (
    <header className="tc-inner-header-style1">
      <div className="container">
        <div className="info col-lg-7">
          <h1 className="js-title"> projeler </h1>
          <div className="text fsz-18 ps-4 pt-4 color-666">
          Sizleride Projelerimiz 
          Arasında görmek isteriz
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
